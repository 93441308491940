import { useEffect, useState } from "react";
import { RECAPTCHA_SITE_KEY } from "@config/config";

const useGoogleReCaptcha = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const executeRecaptcha = (action?: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (typeof window.grecaptcha === "undefined") {
        reject(new Error("Recaptcha is not defined"));
      }

      if (!isLoaded) {
        reject(new Error("Recaptcha is not loaded"));
      }

      window.grecaptcha.execute(`${RECAPTCHA_SITE_KEY}`, {
        action: action ?? ""
      }).then(captcha => {
        resolve(captcha);
      });
    });
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (!isLoaded) {
      interval = setInterval(() => {
        if (typeof window.grecaptcha !== "undefined") {
          grecaptcha.ready(() => {
            setIsLoaded(true);
          });
        }
      }, 300);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isLoaded]);
  return {
    isLoaded,
    executeRecaptcha
  };
};

export default useGoogleReCaptcha;