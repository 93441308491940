/* eslint-disable import/prefer-default-export */
import React, { useEffect } from "react";
import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { getAuth } from "@helpers/auth";
import useAuth from "@hooks/useAuth";
import { wrapper } from "@redux/store";
export const withNonAuthSSR = (getServerSideProps: GetServerSideProps) => wrapper.getServerSideProps(() => async props => {
  const auth = getAuth();

  if (auth.isLoggedIn) {
    return {
      redirect: {
        destination: `/`,
        permanent: false
      }
    };
  }

  return getServerSideProps(props);
}); // eslint-disable-next-line react/function-component-definition

export const withNonAuthClient = (WrappedComponent: React.ComponentType) => () => {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const {
    auth
  } = useAuth();
  const redirect = router.query.redirect && String(router.query.redirect);
  useEffect(() => {
    if (router.isReady && redirect) {
      router.prefetch(redirect);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [router, redirect]);
  useEffect(() => {
    if (router.isReady && auth.isLoggedIn) {
      if (redirect) {
        router.push(redirect);
        return;
      }

      router.push(`/`);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [auth.isLoggedIn, t, router]);
  return <WrappedComponent />;
};